import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroVideo";
import Paletacolor from "../components/global/Paletacolor";
import BlockFour from "../components/block/BlockThree";
import BlockOne from "../components/block/Block_10";
import BlockOneV from "../components/block/Block_11";
import VideoPromocional from "../components/global/VideoPromocional";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}


import { BiLike } from 'react-icons/bi'
import {BsFillPersonCheckFill } from 'react-icons/bs'
import {FaAward} from 'react-icons/fa'

const dbBeneficio = [
    {
        title: 'Best Landscaping',
        icons: <BiLike />,
    },
    {
        title: 'Professional Team',
        icons: <BsFillPersonCheckFill />,
    },
    {
        title: 'Best Quality',
        icons: <FaAward />,
    },

]

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/videostock-8dab7.appspot.com/o/video%2FIrrigation%20Service.mp4?alt=media&token=6d7bcf29-4805-48b9-affb-8692d9e30d60"}
        />

        {/* block de beneficio  */}
        <div className=' w-[90%] mx-auto md:mx-0 md:w-[70%] -mt-10 py-20 relative mb-10 bg-[#4FBA32] flex flex-col md:flex-row  justify-center items-center gap-20 rounded-xl md:rounded-br-[300px]'>
          {
            dbBeneficio.map((item, index) => {
              return (
                <div key={index} className='flex items-center gap-5 text-white'>
                  <span className="text-[30px]">{item.icons}</span>
                  <h4>{item.title}</h4>

                </div>
              )
            })
          }
        </div>  

        <BlockOne
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.stock?.[8]}
          image3={rpdata?.stock?.[7]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        <Directories />

        <div className="relative">
          <div className="absolute w-full flex justify-center md:justify-end z-20">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/imagenes-4d2f6.appspot.com/o/10%20YEARS-01.png?alt=media&token=7e799e0f-178f-4b65-930b-9e3c97f2d121"
              alt="years experience"
              className="w-[50%] md:w-[15%] mr-0 md:mr-40 mt-10 md:mt-20"
            />
          </div>
          <BlockFour
            title={rpdata?.dbSlogan?.[3]?.slogan}
            text={rpdata?.dbHome?.[1]?.text}
            image={rpdata?.stock?.[5]}
          />
        </div>

        <CounterUp image={rpdata?.stock?.[7]} />

        <BlockOneV
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[6]}
          image3={rpdata?.stock?.[1]}
          listsAbout={true}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="overflow-hidden">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className="flex flex-col md:flex-row-reverse w-4/5 mx-auto space-x-4">
                <div className="md:w-1/2 w-full bg-cover bg-center shadow-2xl rounded-lg" style={{ backgroundImage: `url("${rpdata?.gallery?.[80]}")` }} >

                </div>
                <div className=" px-0 md:px-10 md:w-1/2 w-full py-12">
                  <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
                </div>

              </div>
            </div>
            : null
        }

        <ServicesHome />

        {/* Paleta de Colores */}

        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[0]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
